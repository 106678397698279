import { Mark, mergeAttributes, getMarkAttributes } from '@tiptap/core';

/**
 * This extension allows you to create text styles. It is required by default
 * for the `textColor` and `backgroundColor` extensions.
 * @see https://www.tiptap.dev/api/marks/text-style
 */
const TextStyle = Mark.create({
  name: 'textStyle',
  priority: 101,
  addOptions() {
    return {
      HTMLAttributes: {}
    };
  },
  parseHTML() {
    return [{
      tag: 'span',
      getAttrs: element => {
        const hasStyles = element.hasAttribute('style');
        if (!hasStyles) {
          return false;
        }
        return {};
      }
    }];
  },
  renderHTML({
    HTMLAttributes
  }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addCommands() {
    return {
      removeEmptyTextStyle: () => ({
        state,
        commands
      }) => {
        const attributes = getMarkAttributes(state, this.type);
        const hasStyles = Object.entries(attributes).some(([, value]) => !!value);
        if (hasStyles) {
          return true;
        }
        return commands.unsetMark(this.name);
      }
    };
  }
});
export { TextStyle, TextStyle as default };
